import * as React from "react"
import CaseStudyTemplate from "../components/case-study-template";
import { StaticImage } from "gatsby-plugin-image";

const content = {
	title: `Kellogg’s`,
	heading: 'Kellogg’s',
	emphasisedText: 'In this case, we provided only the draw and digital fulfilment aspect of the promo',
	text: [
		'Wunderman Thompson ran the Kellogg’s Back to School promotion site, and provided KO with weekly entry lists, which we ran through our Quickdraw system. We provided WT with a list of winners, which they validated.',
		'Once validated, KO triggered eDM blasts with Woolworths digital vouchers.'
	],
	numbers: {
		Entrants: '3000',
		Winners: '60',
		'In prizes': '$60k',
	},
};

export default function Kellogs() {
	return (
		<CaseStudyTemplate 
			heading={content.heading}
			superHeading={content.title}
			image={<StaticImage alt="Hero image" src="../images/Kellogs@3x.png" width={500} height={500} />}
			content={{
				title: content.emphasisedText,
				text: content.text,
			}}
			numbers={content.numbers}
		/>
	)
}
